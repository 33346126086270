const validateRepartion = (_, formValues, num, index_parent) => {
  const code_hotel = formValues[`code_hotel_${index_parent}_${num}`];
  const rooms = formValues[index_parent][num][code_hotel]["nbr_rooms"];
  const adults = formValues[`adults`];
  const children = formValues[`children`];
  let repartition = {};
  // Pas de duplication
  for (let i = 0; i < adults; i++) {
    for (let j = 0; j < rooms; j++) {
      let code = formValues[index_parent][num][code_hotel]["room"][j]["code"];
      if (code === "") return `- Vous n'avez sélèctionné la chambre ${j + 1}.`;
      if (
        typeof formValues[index_parent][num][code_hotel]["room"][j][
          "repartition"
        ]["adults"] === "object" &&
        formValues[index_parent][num][code_hotel]["room"][j]["repartition"][
          "adults"
        ].includes(`adult_${i + 1}`)
      ) {
        if (`adult_${i + 1}` in repartition) {
          return `- L'adulte ${i + 1} est réparti dans plusieurs chambres.`;
        } else {
          repartition[`adult_${i + 1}`] = j + 1;
        }
      }
    }
  }

  for (let i = 0; i < children; i++) {
    for (let j = 0; j < rooms; j++) {
      if (
        typeof formValues[index_parent][num][code_hotel]["room"][j][
          "repartition"
        ]["children"] === "object" &&
        formValues[index_parent][num][code_hotel]["room"][j]["repartition"][
          "children"
        ].includes(`children_${i + 1}`)
      ) {
        if (`children_${i + 1}` in repartition) {
          return `- L'enfant ${i + 1} est réparti dans plusieurs chambres.`;
        } else {
          repartition[`children_${i + 1}`] = j + 1;
        }
      }
    }
  }
  // Vérifier si tous les voyageurs sont placées.
  if (parseInt(Object.keys(repartition).length) !== parseInt(adults + children))
    return "- Les voyageurs ne sont pas tous placés dans les chambres, veuillez les répartir tous dans les chambres.";
  return true;
};

const validateRoom = (
  value,
  _,
  filteredRooms,
  room,
  rooms,
  nbr_nights,
  flag
) => {
  if (filteredRooms.length === 0) {
    return `- Il n'y pas de répartition possible pour La chambre ${room + 1}`;
  } else if (
    (value && !filteredRooms.includes(value)) ||
    value === undefined ||
    value === ""
  ) {
    return `- La chambre ${room + 1} n'est pas sélectionné.`;
  } else if (flag !== "ADD_ROOM_CATEGORY") {
    let { duree_min } = rooms[value];
    if (duree_min !== 0 && nbr_nights < duree_min)
      return `- Le nombre de nuit minimal de la chambre sélectionnée est de ${duree_min} nuit(s) .`;
  }

  return true;
};
export { validateRepartion, validateRoom };

import { differenceInDays, addDays, format } from "date-fns";
import { getLastKey } from "../helper";
import { createSelector } from "reselect";

const getUserSelectionSate = (state) => state.userSelectionReducer;
const getComponents = (state) => state.userSelectionReducer.components;
const getDatas = (state) => state.userSelectionReducer.data;

const getNextDest = createSelector(
  getDatas,
  (data) => data["next_dest"] // Transformation logic can be applied here if needed
);

const getFormDatas = (state) => state.userSelectionReducer.form_data;

const getAvailableDays = (state) => {
  return getFormDatas(state)["available_days"];
};

const getNumIsland = (state) => {
  return getFormDatas(state)["num_island"];
};

const Islands = (state) => {
  return getFormDatas(state)["islands"];
};

const getIslandIndex = (state) => {
  return getFormDatas(state)["num_island"];
};

const getCurrentDest = (state) => {
  const datas = getDatas(state);
  if ("current_dest" in datas) return datas["current_dest"];
  return false;
};

const getDiffDays = (state, num) => {
  const datas = getDatas(state);
  const {
    staydates: { startDate, endDate },
  } = datas;
  let nights_in_hotel = 0;

  if ("hotels" in datas && Object.keys(datas["hotels"] || {}).length > 0) {
    for (const i in datas["hotels"]) {
      if (parseInt(i) === parseInt(num)) {
        break;
      }
      nights_in_hotel += datas["hotels"][i]["nbr_nights"];
    }
  }
  const [start_year, start_month, start_day] = startDate.split("-").map(Number);
  const date1 = new Date(start_year, start_month - 1, start_day, 0, 0);
  const [end_year, end_month, end_day] = endDate.split("-").map(Number);
  const date2 = new Date(end_year, end_month - 1, end_day, 1, 0);
  let daysDifference =
    differenceInDays(new Date(date2), new Date(date1)) -
    parseInt(nights_in_hotel);
  return daysDifference;
};

const isProductSelected = (index) => (state) => {
  const datas = getDatas(state);
  return "hotels" in datas &&
    index in datas["hotels"] &&
    Object.keys(datas["hotels"][index]).length > 0
    ? true
    : false;
};

const getLastRecapComponent = (components, index_component) => {
  const children = components[index_component]["children"];
  const recap_components = children.filter(
    (component) => component.mode === "recap"
  );
  const last_index = getLastKey(recap_components);
  const last_num = recap_components[last_index]["num"];
  return last_num;
};

const calculateAvailableDays = (state) => {
  const datas = state;
  const {
    staydates: { startDate, endDate },
  } = datas;

  let nights_in_hotel = 0;

  if ("hotels" in datas && Object.keys(datas["hotels"] || {}).length > 0) {
    for (const index in datas["hotels"]) {
      for (const num in datas["hotels"][index]) {
        nights_in_hotel += datas["hotels"][index][num]["nbr_nights"];
      }
    }
  }
  const [start_year, start_month, start_day] = startDate.split("-").map(Number);
  const date1 = new Date(start_year, start_month - 1, start_day, 0, 0);
  const [end_year, end_month, end_day] = endDate.split("-").map(Number);
  const date2 = new Date(end_year, end_month - 1, end_day, 1, 0);
  let daysDifference =
    differenceInDays(new Date(date2), new Date(date1)) -
    parseInt(nights_in_hotel);
  return daysDifference;
};

const getLastProductData = (state, index) => {
  const datas = getDatas(state);
  let data = {};
  let lastKey = 0;
  if (
    "hotels" in datas &&
    index in datas["hotels"] &&
    Object.keys(datas["hotels"][index] || {}).length > 0
  ) {
    const keys = Object.keys(datas["hotels"][index]);
    lastKey = parseInt(keys[keys.length - 1]);
    data = datas["hotels"][index][lastKey];
  }
  return { data, lastKey };
};

const getProductsStayDates = (datas, nbr_nights, num_hotel, index_parent) => {
  const {
    staydates: { startDate },
  } = datas;

  let nights_in_hotel = 0;
  if (Object.keys(datas["hotels"]).length > 0) {
    for (const index in datas["hotels"]) {
      for (const num in datas["hotels"][index]) {
        if (
          !(
            parseInt(num) === parseInt(num_hotel) &&
            parseInt(index) === parseInt(index_parent)
          )
        ) {
          nights_in_hotel += datas["hotels"][index][num]["nbr_nights"];
        }
      }
    }
  }
  const days_before = nights_in_hotel;
  const start_date_no_format = addDays(new Date(startDate), days_before);
  const end_date_no_format = addDays(
    start_date_no_format,
    parseInt(nbr_nights)
  );
  const start_date = format(start_date_no_format, "yyyy-MM-dd");
  const end_date = format(end_date_no_format, "yyyy-MM-dd");
  return { start_date, end_date };
};

const getCountHotels = (dest) => (state) => {
  const hotels = getDatas(state)["hotels"];

  if (!(dest in hotels)) return 0;

  const uniqueHotelCodes = new Set(
    Object.values(hotels[dest]).map((hotel) => hotel.code)
  );

  return uniqueHotelCodes.size;
};

const getCurrentCountHotels = (state) => {
  const hotels = getDatas(state)["hotels"];
  let count = 0;
  for (let dest in hotels) {
    if (hotels.hasOwnProperty(dest)) {
      count += Object.keys(hotels[dest]).length;
    }
  }
  return count;
};

const canAddProducts = (index) => (prod_max) => (state) => {
  if (prod_max === 0) return true;
  let codes = [];
  let real_count = 0;
  const hotels = getDatas(state)["hotels"];
  for (const num in hotels[index]) {
    let code = hotels[index][num]["code"];
    if (!codes.includes(code)) {
      real_count += 1;
      codes.push(code);
    }
  }
  return parseInt(prod_max) === parseInt(real_count) ? false : true;
};

const getComponent = (type) => (state) => {
  const components = getComponents(state);
  const component = components.filter(
    (component) => component.component === type
  );
  return component.length > 0 ? component[0] : {};
};

const isMultiDesti = (state) => {
  const { next_dest } = getDatas(state);
  if (next_dest !== undefined && next_dest !== "none") {
    return true;
  }
  return false;
};

const isMultiDestiCurrenty = (state) => {
  const { next_dest, current_dest } = getDatas(state);
  if (next_dest !== undefined && next_dest !== "none") {
    return next_dest === current_dest;
  }
  return false;
};

const getVisitedDesti = (state) => {
  const { next_dest, current_dest, dest } = getDatas(state);
  if (
    next_dest !== undefined &&
    next_dest !== "none" &&
    next_dest === current_dest
  )
    return [dest, next_dest];

  return [dest];
};

export {
  getCountHotels,
  getUserSelectionSate,
  getComponents,
  getDatas,
  getAvailableDays,
  getDiffDays,
  isProductSelected,
  getLastProductData,
  getLastRecapComponent,
  canAddProducts,
  getProductsStayDates,
  calculateAvailableDays,
  getCurrentDest,
  getIslandIndex,
  getCurrentCountHotels,
  getFormDatas,
  getComponent,
  isMultiDesti,
  isMultiDestiCurrenty,
  getVisitedDesti,
  getNextDest,
  getNumIsland,
  Islands,
};

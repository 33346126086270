import { configureStore } from "@reduxjs/toolkit";
import { userSelectionReducer } from "../slice/userSelectionSlice";
import { destinationReducer } from "../slice/destinationSlice";
import { flightReducer } from "../slice/flightSlice";
import { productsReducer } from "../slice/productsSlice";
import { roomsReducer } from "../slice/roomsSlice";
import { assuranceReducer } from "../slice/assuranceSlice";
import { realTimeReducer } from "../slice/realTimeSlice";
import { combineReducers } from "redux";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas";
import Cookies from "js-cookie";

const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
  userSelectionReducer,
  destinationReducer,
  flightReducer,
  productsReducer,
  roomsReducer,
  assuranceReducer,
  realTimeReducer,
});

const isAdmin = Cookies.get("is-admin");
export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: false,
    }).concat(sagaMiddleware);

    if (isAdmin === "admin" || process.env.REACT_APP_DEBUG === "DEBUG") {
      middlewares.push(logger); // Add logger middleware if user is admin
    }

    return middlewares;
  },
  devTools: true,
});

sagaMiddleware.run(rootSaga);

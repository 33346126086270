import {
  recapMode,
  addComponent,
  removeComponent,
  ComponentMode,
  updateDatas,
  removeDatas,
} from "../slice/userSelectionSlice";
import { fetchRooms, resetRooms } from "../slice/roomsSlice";
import { fetchAssurance } from "../slice/assuranceSlice";
import { fetchDestination } from "../slice/destinationSlice";
import { fetchRealTime, resetRealTime } from "../slice/realTimeSlice";
import { getProducts, resetProducts } from "../slice/productsSlice";
import { resetFlights } from "../slice/flightSlice";
import { getDataTransfert, ProductsExist } from "../selectors/products";
import { getExcursionsDatas } from "../selectors/excursion";
import {
  getLastProductData,
  getDatas,
  getFormDatas,
  getAvailableDays,
  getComponents,
  getComponent,
} from "../selectors/userSelection";
import { RoomCodeExist } from "../selectors/rooms";
import {
  IsMultiIsland,
  getIslands,
  getDestination,
  isPossibleCombination,
  DestCodeExist,
  possibleInteriorFlights,
} from "../selectors/destination";
import { excursionHasValues } from "../selectors/form";
import {
  getFlightInfo,
  getTotalLag,
  getDetailDuration,
} from "../selectors/flights";
import { getAssurances } from "../selectors/assurance";

export const appBrain = (values) => (dispatch, getState) => {
  let state = getState();

  let {
    dest,
    next_dest,
    index,
    block,
    default_code_product,
    button: { type },
    api_key,
  } = values;
  const is_multi_island = IsMultiIsland(dest)(state);
  var data = "";
  switch (type) {
    case "SELECT_STAYDATES":
      dispatch(updateDatas({ ...values, is_multi_island }));
      dispatch(recapMode());
      dispatch(addComponent({ type: "travelers", mode: "popup" }));
      break;

    case "SELECT_TRAVELERS":
      dispatch(updateDatas({ ...values }));
      dispatch(
        ComponentMode({
          type: "travelers",
          mode: "recap",
        })
      );

      if (is_multi_island === "1") {
        const islands = getIslands(dest)(getState());
        dispatch(
          addComponent({
            type: "islands",
            dest,
            islands_code: islands,
            mode: "popup",
          })
        );
      }

      if (is_multi_island !== "1") {
        dispatch(
          addComponent({
            type: "hotels",
            dest,
            mode: "button",
            default_values: {
              code: default_code_product,
            },
            disabled: false,
          })
        );
      }

      dispatch(
        addComponent({
          type: "flight",
          flight_type: "external",
          mode: "button",
          disabled: true,
        })
      );

      dispatch(
        addComponent({
          type: "excursion",
          mode: "button",
          dest,
          next_dest,
          disabled: true,
        })
      );

      dispatch(
        addComponent({ type: "assurance", mode: "button", disabled: true })
      );

      if (next_dest !== undefined && next_dest !== "none") {
        dispatch(getDest({ dest: next_dest, api_key }));
      }

      break;

    case "SELECT_FLIGHT":
      const { code } = values["flight"]["external"];
      const duration = getDetailDuration(code)(getState());
      const flight_info = getFlightInfo(getState(), code);
      const { total_lag, lag_aller, lag_retour } = getTotalLag(flight_info);
      dispatch(
        updateDatas({ ...values, total_lag, lag_aller, lag_retour, duration })
      );
      data = getDatas(getState());
      dispatch(fetchRealTime({ data }));
      dispatch(
        ComponentMode({
          type: "flight",
          mode: "recap",
          flight_type: block.flight_type,
        })
      );

      break;

    case "SELECT_ISLAND":
      dispatch(updateDatas({ ...values }));
      dispatch(
        ComponentMode({
          type: "island",
          mode: "recap",
          index,
        })
      );
      let island = values[`island_${block.num_island}`];
      dispatch(
        addComponent({
          type: "hotels",
          mode: "popup",
          dest: block.dest,
          index: index + 1,
          island,
        })
      );

      break;

    case "SELECT_HOTELS":
      dispatch(updateDatas({ ...values }));
      dispatch(
        ComponentMode({
          type: "hotel",
          mode: "recap",
          num: block.num,
          index: block.index_parent,
        })
      );
      const real_time_quote_component = getComponent("real_time_quote")(
        getState()
      );

      if (Object.keys(real_time_quote_component).length === 0)
        dispatch(
          addComponent({
            type: "real_time_quote",
            mode: "show",
          })
        );
      data = getDatas(getState());
      dispatch(fetchRealTime({ data }));
      const available_days = getAvailableDays(getState());
      const is_possible_combination = isPossibleCombination(dest)(state);
      const { possible } = possibleInteriorFlights(block.island !== undefined)(
        block.dest
      )(state);
      if (
        available_days === 0 &&
        (is_multi_island !== "1" ||
          (is_multi_island === "1" &&
            is_possible_combination === true &&
            possible === true))
      ) {
        const data_transfert = getDataTransfert(getState(), block);
        const { transfert, location } = data_transfert;
        const flight_component = getComponent("flight")(getState());
        const excursion_component = getComponent("excursion")(getState());
        const assurance_component = getComponent("assurance")(getState());
        const special_request_component = getComponent("special_request")(
          getState()
        );
        const validation_section_component = getComponent("validation_section")(
          getState()
        );
        if (
          Object.keys(transfert).length !== 0 ||
          Object.keys(location).length !== 0
        ) {
          dispatch(
            addComponent({
              type: "transfert",
              mode: "popup",
              dest: block.dest,
              island: block.island,
              index: block.index_parent + 1,
              data_transfert,
            })
          );
        }
        if (flight_component.mode === "button")
          dispatch(
            ComponentMode({
              type: "flight",
              flight_type: "external",
              mode: "button",
              disabled: false,
            })
          );

        if (excursion_component.mode === "button") {
          const { has_data, excursions_datas_by_desti } = getExcursionsDatas(
            getState()
          );
          if (has_data === true)
            dispatch(
              ComponentMode({
                type: "excursion",
                mode: "button",
                disabled: false,
                datas: excursions_datas_by_desti,
              })
            );
        }

        if (assurance_component.mode === "button")
          dispatch(
            ComponentMode({
              type: "assurance",
              mode: "button",
              disabled: false,
            })
          );

        if (Object.keys(special_request_component).length === 0)
          dispatch(addComponent({ type: "special_request", mode: "edit" }));
        if (Object.keys(validation_section_component).length === 0)
          dispatch(addComponent({ type: "validation_section" }));
      }

      break;

    case "SELECT_TRANSFERT":
      const desti = block.dest;
      const transfert =
        "transfert" in values && desti in values[`transfert`]
          ? values[`transfert`][desti]["code"]
          : null;

      let datas = getDatas(getState());
      let current_dest = datas["current_dest"];
      let remain_days = getAvailableDays(state);
      if (transfert !== null) {
        const type_transfert =
          transfert in block.data["transfert"] ? "transfert" : "rental";
        if (type_transfert === "transfert") {
          const { bl_linked_transfers } = getDestination(block.dest)(
            getState()
          );
          dispatch(
            updateDatas({
              ...values,
              bl_linked_transfers,
              type_transfert,
            })
          );
        } else {
          const rental_code = block.data["location"][transfert]["rental_code"];
          dispatch(
            updateDatas({
              ...values,
              type_transfert,
              rental_code,
            })
          );
        }

        dispatch(ComponentMode({ type: "transfert", mode: "recap", index }));
        data = getDatas(getState());
        dispatch(fetchRealTime({ data }));
      } else {
        dispatch(ComponentMode({ type: "transfert", mode: "button", index }));
      }
      if (remain_days > 0) {
        if (is_multi_island === "1") {
          const islands = getIslands(dest)(state);
          dispatch(
            addComponent({
              type: "islands",
              dest,
              islands_code: islands,
              mode: "popup",
            })
          );
        } else {
          dispatch(
            addComponent({
              type: "hotels",
              mode: "popup",
              dest: current_dest,
              default_values: { code: "" },
              index: index + 1,
            })
          );
        }
      }

      break;

    case "SELECT_ASSURANCE":
      dispatch(updateDatas({ ...values }));
      data = getDatas(getState());
      dispatch(fetchRealTime({ data }));
      dispatch(ComponentMode({ type: "assurance", mode: "recap" }));

      break;

    case "SELECT_EXCURSION":
      const has_values = excursionHasValues(values);
      if (has_values === true) {
        dispatch(updateDatas({ ...values }));
        data = getDatas(getState());
        dispatch(fetchRealTime({ data }));
        dispatch(ComponentMode({ type: "excursion", mode: "recap" }));
      } else {
        dispatch(
          ComponentMode({
            type: "excursion",
            mode: "button",
            datas: block.datas,
          })
        );
      }
      break;

    case "SELECT_NAMES":
      dispatch(updateDatas({ ...values }));
      data = getDatas(getState());
      dispatch(fetchRealTime({ data, mode: "quote" }));
      dispatch(ComponentMode({ type: "validation_section" }));
      break;

    default:
    // do nothing
  }
};

export const callToAction = (values) => (dispatch, getState) => {
  const state = getState();
  let last_product_data = {};
  const {
    next_dest,
    button: { type },
    block,
    index,
  } = values;
  let { dest, island } = block;
  switch (type) {
    case "CALL_TO_ACTION_SAME_DEST_HOTELS":
      dispatch(
        addComponent({
          type: "hotels",
          mode: "popup",
          dest,
          default_values: { code: "" },
          index,
        })
      );

      break;

    case "CALL_TO_ACTION_NEXT_ISLAND":
      dispatch(updateDatas({ ...values, index_parent: index }));
      const islands = getIslands(dest)(state);
      dispatch(
        addComponent({
          type: "islands",
          dest,
          islands_code: islands,
          index: index + 1,
          mode: "popup",
        })
      );

      break;

    case "CALL_TO_ACTION_NEXT_DEST_HOTELS":
      dispatch(updateDatas({ ...values, current_dest: next_dest }));
      let data_transfert = getDataTransfert(getState(), block);
      if (
        Object.keys(data_transfert.transfert).length !== 0 ||
        Object.keys(data_transfert.location).length !== 0
      ) {
        dispatch(
          addComponent({
            type: "transfert",
            mode: "popup",
            dest: block.dest,
            data_transfert,
            index: index + 1,
          })
        );
      } else {
        dispatch(
          addComponent({
            type: "hotels",
            dest: next_dest,
            mode: "popup",
            index: index + 1,
          })
        );
      }
      break;

    case "CALL_TO_ACTION_ADD_ROOM_CATEGORY":
      last_product_data = getLastProductData(getState(), index);
      const code_hotel =
        "code" in last_product_data["data"]
          ? last_product_data["data"]["code"]
          : "";
      dispatch(
        addComponent({
          type: "hotels",
          mode: "popup",
          dest,
          island,
          default_values: {
            code: code_hotel,
          },
          index,
          flag: "ADD_ROOM_CATEGORY",
        })
      );
      break;

    default:
    // do nothing
  }
};

export const PopUpMode = (values) => (dispatch) => {
  const { block, index } = values;
  const { component, num, flight_type, index_parent } = block;
  dispatch(updateDatas({ button: { type: "UPDATE_MODE" }, mode: "edit" }));
  const mode = "popup";
  switch (component) {
    case "hotel":
      dispatch(
        ComponentMode({
          type: "hotel",
          mode,
          num,
          index: index_parent,
        })
      );
      break;

    case "flight":
      dispatch(
        ComponentMode({
          type: "flight",
          mode,
          flight_type,
        })
      );
      break;

    case "transfert":
      dispatch(
        ComponentMode({
          type: "transfert",
          mode,
          index,
        })
      );
      break;

    case "assurance":
    case "excursion":
    case "islands":
      dispatch(ComponentMode({ type: component, mode }));
      break;

    default:
    // do nothing
  }
};

export const editMode = (values) => (dispatch, getState) => {
  const { block, index, unregister } = values;
  const { next_dest, ...datas } = getDatas(getState());
  const { component, flight_type, dest, index_parent, num } = block;
  const is_multi_island = IsMultiIsland(dest)(getState());
  dispatch(updateDatas({ button: { type: "UPDATE_MODE" }, mode: "edit" }));
  const mode = "popup";
  var data = {};
  var components = [];
  switch (component) {
    case "hotel":
      const { hotels } = getDatas(getState());
      dispatch(updateDatas({ button: { type: "UPDATE_CURRENT_DEST" }, dest }));
      dispatch(
        ComponentMode({
          type: "hotel",
          mode: "popup",
          index: index_parent,
          num,
        })
      );
      dispatch(
        removeDatas({ block, index: index_parent, num, flag: "WIPE_DATES" })
      );
      //hotels
      Object.keys(hotels[index_parent]).forEach((key) => {
        if (key > num) {
          dispatch(
            removeComponent({
              type: "hotel",
              index: index_parent,
              num: num + 1,
            })
          );
          dispatch(removeDatas({ block, index: index_parent, num: key }));
        }
      });
      // Cas Mono Destination ou Multi destination et on est en deuxième destination
      if (
        is_multi_island !== "1" &&
        (next_dest === "none" || next_dest === undefined || next_dest === dest)
      ) {
        // transfert
        dispatch(
          removeComponent({ type: "transfert", index: index_parent + 1 })
        );
        dispatch(removeDatas({ block: { component: "transfert", dest } }));
      } else {
        // Multi destinationn et on est en première destination ou Multi island
        components = getComponents(getState());
        components.forEach((component, key) => {
          if (key > index_parent) {
            switch (component.component) {
              case "hotels":
                dispatch(
                  removeComponent({
                    type: "hotels",
                    index: index_parent + 1,
                  })
                );
                dispatch(
                  removeDatas({
                    block: component,
                    index: component.index_parent,
                  })
                );
                break;

              case "islands":
              case "transfert":
                dispatch(
                  removeComponent({
                    type: component.component,
                    index: index_parent + 1,
                  })
                );
                dispatch(removeDatas({ block: component }));
                break;

              case "flight":
                if (is_multi_island !== "1") {
                  dispatch(
                    ComponentMode({
                      type: "flight",
                      mode: "button",
                      disabled: true,
                    })
                  );
                  dispatch(removeDatas({ block: component }));
                  dispatch(resetFlights());
                  unregister(`flight`);
                }
                break;

              case "excursion":
                dispatch(
                  ComponentMode({
                    type: "excursion",
                    mode: "button",
                    disabled: true,
                  })
                );
                dispatch(removeDatas({ block: component }));
                unregister(`excursion`);
                break;

              default:
            }
          }
        });
      }
      break;

    case "travelers":
    case "date":
      dispatch(
        ComponentMode({
          type: component,
          mode,
        })
      );
      dispatch(resetProducts());
      dispatch(resetRooms());
      dispatch(resetRealTime());
      if (component === "travelers")
        dispatch(
          updateDatas({
            button: { type: "UPDATE_CURRENT_DEST" },
            dest: datas["dest"],
          })
        );
      components = getComponents(getState());
      let index_for_removal = index + 1;
      components.forEach((component, key) => {
        if (key > index) {
          switch (component.component) {
            case "hotels":
              dispatch(
                removeComponent({
                  type: "hotels",
                  index: index_for_removal,
                })
              );
              dispatch(
                removeDatas({
                  block: component,
                  index: component.index_parent,
                })
              );
              unregister(`${component.index_parent}`);
              break;

            case "flight":
              dispatch(
                removeComponent({
                  type: "flight",
                  index: index_for_removal,
                })
              );
              dispatch(removeDatas({ block: component }));
              dispatch(resetFlights());
              unregister(`flight`);
              break;

            case "transfert":
            case "islands":
            case "assurance":
              dispatch(
                removeComponent({
                  type: component.component,
                  index: index_for_removal,
                })
              );
              dispatch(removeDatas({ block: component }));
              break;

            case "excursion":
              dispatch(
                removeComponent({
                  type: component.component,
                  index: index_for_removal,
                })
              );
              dispatch(removeDatas({ block: component }));
              unregister(`excursion`);
              break;

            case "real_time_quote":
            case "special_request":
            case "validation_section":
            case "travelers":
              dispatch(
                removeComponent({
                  type: component.component,
                  index: index_for_removal,
                })
              );
              break;
            default:
          }
        }
      });
      break;

    case "islands":
      dispatch(
        ComponentMode({
          type: "islands",
          mode,
          index,
        })
      );
      components = getComponents(getState());
      components.forEach((component, key) => {
        if (key > index) {
          switch (component.component) {
            case "hotels":
              dispatch(
                removeComponent({
                  type: "hotels",
                  index: index + 1,
                })
              );
              dispatch(
                removeDatas({
                  block: component,
                  index: component.index_parent,
                })
              );
              break;

            case "islands":
            case "transfert":
              dispatch(
                removeComponent({
                  type: component.component,
                  index: index + 1,
                })
              );
              dispatch(removeDatas({ block: component }));
              break;

            case "excursion":
              dispatch(
                ComponentMode({
                  type: "excursion",
                  mode: "button",
                  disabled: true,
                })
              );
              dispatch(removeDatas({ block: component }));
              unregister(`excursion`);
              break;

            default:
          }
        }
      });
      break;

    case "flight":
      data = getDatas(getState());
      dispatch(fetchRealTime({ data, flag: "no_flights" }));
      dispatch(
        ComponentMode({
          type: "flight",
          mode,
          flight_type,
        })
      );
      break;

    case "excursion":
    case "transfert":
    case "assurance":
      dispatch(ComponentMode({ type: component, mode, index }));
      break;

    default:
    // do nothing
  }
};

export const deleteMode = (values) => (dispatch, getState) => {
  const { block, unregister, index, setValue } = values;
  const { component } = block;
  dispatch(updateDatas({ button: { type: "UPDATE_MODE" }, mode: "edit" }));
  var data = {};
  switch (component) {
    case "flight":
      setValue("no_flight", true);
      dispatch(
        ComponentMode({
          type: "flight",
          mode: "button",
          disabled: false,
          index,
        })
      );
      dispatch(removeDatas({ block }));
      dispatch(resetFlights());
      unregister(`flight`);
      break;
    case "excursion":
      dispatch(
        ComponentMode({
          type: "excursion",
          mode: "button",
          disabled: false,
          index,
        })
      );
      dispatch(removeDatas({ block }));
      unregister(`excursion`);
      break;
    default:
  }
  data = getDatas(getState());
  dispatch(fetchRealTime({ data }));
};

export const cancel = (values) => (dispatch, getState) => {
  const { block, index } = values;
  const { component, num, dest, num_island } = block;
  const dataState = getDatas(getState());

  switch (component) {
    case "travelers":
      dispatch(
        removeComponent({
          type: component,
          index,
        })
      );
      dispatch(
        ComponentMode({
          type: "date",
          mode: "popup",
          index: 0,
        })
      );
      break;

    case "hotel":
      let product_in_data = false;
      let hotels_in_data = false;
      if ("hotels" in dataState && index in dataState["hotels"]) {
        hotels_in_data = true;
        if (num in dataState["hotels"][index]) {
          product_in_data = true;
        }
      }

      // En mode ajout et pas d autres produits
      if (product_in_data === false && hotels_in_data === false) {
        dispatch(
          ComponentMode({
            type: "hotel",
            mode: "button",
            disabled: false,
            num,
            index,
          })
        );
      } else if (product_in_data === false && hotels_in_data === true) {
        // En mode ajout et il y a d autres produits (juste fermer le popoup)
        //  Supprimer le composant
        dispatch(
          removeComponent({
            type: "hotel",
            index,
            num,
          })
        );
        dispatch(
          ComponentMode({
            type: "hotels",
            mode: "recap",
            index,
          })
        );
      } else if (product_in_data === true) {
        // En mode Edit
        dispatch(
          ComponentMode({
            type: "hotel",
            mode: "recap",
            num,
            index,
          })
        );
      }
      break;

    case "transfert":
      if (!(dest in dataState["transfert"]) && !(dest in dataState["rental"])) {
        // En mode ajout , le compoment 'transfert' deveient en mode add
        dispatch(
          ComponentMode({
            type: "transfert",
            mode: "button",
            index,
            disabled: false,
          })
        );
      } else {
        // En mode edit
        dispatch(
          ComponentMode({
            type: "transfert",
            mode: "recap",
            index,
          })
        );
      }
      break;

    case "excursion":
      if (Object.keys(dataState["excursions"]).length === 0) {
        // En mode ajout , le compoment 'transfert' devient en mode add
        dispatch(
          ComponentMode({
            type: "excursion",
            mode: "button",
            index,
            disabled: false,
          })
        );
      } else {
        // En mode edit
        dispatch(
          ComponentMode({
            type: "excursion",
            mode: "recap",
            index,
          })
        );
      }
      break;

    case "assurance":
      if (dataState["assurance"].length === 0) {
        // En mode ajout , le compoment 'transfert' devient en mode add
        dispatch(
          ComponentMode({
            type: "assurance",
            mode: "button",
            index,
            disabled: false,
          })
        );
      } else {
        // En mode edit
        dispatch(
          ComponentMode({
            type: "assurance",
            mode: "recap",
            index,
          })
        );
      }
      break;

    case "flight":
      if (Object.keys(dataState["flight"]).length === 0) {
        // En mode ajout
        dispatch(
          ComponentMode({
            type: "flight",
            flight_type: "external",
            mode: "button",
            index,
            disabled: false,
          })
        );
        dispatch(resetFlights());
      } else {
        // En mode edit
        const data = getDatas(getState());
        dispatch(fetchRealTime({ data }));
        dispatch(
          ComponentMode({
            type: "flight",
            flight_type: "external",
            mode: "recap",
            index,
          })
        );
      }
      break;

    case "islands":
      const { islands } = getFormDatas(getState());
      if (!(num_island in islands)) {
        // En mode ajout
        dispatch(
          ComponentMode({
            type: "island",
            mode: "button",
            index,
            disabled: false,
          })
        );
      } else {
        // En mode edit

        dispatch(
          ComponentMode({
            type: "island",
            mode: "recap",
            index,
          })
        );
      }
      break;
    default:
  }
};

export const getRooms = (values) => (dispatch, getState) => {
  const state = getState();
  const code = values["code_hotel"];
  if (!RoomCodeExist(state, code)) dispatch(fetchRooms(values));
};

export const getDest = (values) => (dispatch, getState) => {
  let { dest, api_key } = values;
  let dest_code_exist = DestCodeExist(getState(), dest);
  if (dest_code_exist === false) dispatch(fetchDestination({ dest, api_key }));
};

export const getAssurance = (values) => (dispatch, getState) => {
  const { api_key } = values;
  const assurances = getAssurances(getState());
  if (Object.keys(assurances).length === 0) {
    dispatch(fetchAssurance({ api_key }));
  }
};

export const getProductsEnhanced = (values) => (dispatch, getState) => {
  const state = getState();
  const dest = values["products_desti"];
  const is_multi_island = IsMultiIsland(dest)(state);
  if (!ProductsExist(dest)(state))
    dispatch(getProducts({ ...values, products_desti: dest, is_multi_island }));
};

import React from "react";
import { PopUpMode } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "../components/Checkbox";
import { useWatch, useFormContext } from "react-hook-form";
import { getDestination } from "../selectors/destination";

const ButtonModeComponent = ({ name, disabled, block = {}, index }) => {
  const dispatch = useDispatch();
  const { register } = useFormContext();
  const { component, dest } = block;
  const no_flight = useWatch({
    name: `no_flight`,
  });
  const destination = useSelector(getDestination(dest))["label_destination"];
  let disable = disabled;
  if (component === "flight" && no_flight === true) disable = true;

  const validate = (value) => {
    return (
      value ||
      `Sélectionnez un vol ou cochez l'option "Pas de vol international".`
    );
  };

  const validateTransfert = () => {
    return `Veuillez choisir un transfert pour la destination ${destination}.`;
  };

  return (
    <>
      <div className="bg-white p-5 rounded-md">
        <div className="flex justify-between items-center">
          <div>
            <h3 className="gold-color text-fs-21 leading-lh-27 merriweather font-bold">
              {name}
            </h3>
            {component === "flight" && disabled === false && (
              <div className="flex items-center pt-3">
                <Checkbox
                  name={"Pas de vol international"}
                  id={"no_flight"}
                  validate={validate}
                />
              </div>
            )}
            {component === "transfert" && (
              <input
                type="hidden"
                {...register(`transfert_${dest}_hidden`, {
                  validate: validateTransfert,
                })}
                value={false}
              />
            )}
          </div>
          <button
            type="button"
            disabled={disable}
            className="p-16-35 main-bg-color leading-lh-15 text-white br-26 text-fs-14 w-value-200 disabled:bg-disabled-button"
            onClick={() => dispatch(PopUpMode({ block, index }))}
          >
            Ajouter
          </button>
        </div>
      </div>
    </>
  );
};

export default ButtonModeComponent;

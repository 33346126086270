import { React, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useSelector } from "react-redux";
import {
  getRealTimeApi,
  isSuccess,
  isLoading,
  isError,
} from "../selectors/realTime";
import { getDatas } from "../selectors/userSelection";
import warning from "../img/icons/warning.svg";
import LinkButton from "../components/LinkButton";
import ValidateButton from "../components/ValidateButton";
import logo from "../img/logo-australlagons-third.svg";
import { getAllDestinations } from "../selectors/destination";

const ValidationSection = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  const { mode, api_key } = useSelector(getDatas);
  const {
    register,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const { case_no, case_book_url, case_prepare_url, items } =
    useSelector(getRealTimeApi);
  const Success = useSelector(isSuccess);
  const Loading = useSelector(isLoading);
  const Error = useSelector(isError);
  let validateStrings = [
    "vendor",
    "main_traveler",
    "dest",
    "no_flight",
    "email",
    "surname_traveler",
    "name_traveler",
  ];
  const destinations = useSelector(getAllDestinations);
  Object.keys(destinations).forEach((key) => {
    validateStrings.push(`transfert_${key}_hidden`);
  });

  return (
    <>
      {mode === "edit" && (
        <>
          <div className="bg-white p-5 rounded-md main-color validation-section">
            {api_key.length > 0 && (
              <>
                <span className="text-fs-14 leading-lh-25 mb-value-40 block font-bold">
                  Veuillez renseigner le nom du voyageur principal et le prénom
                  du vendeur afin de pouvoir partager le devis à votre client.
                  Si vous êtes une agence Selectour ou Havas, merci d’indiquer
                  également votre code Selectour + ou Perf +.
                </span>
                <div className="flex justify-center">
                  <>
                    <div className="w-320 flex flex-col mr-5">
                      <span className="font-bold mb-2.5 text-fs-14">
                        Nom du voyageur principal
                      </span>
                      <input
                        type="text"
                        className="rounded-md border border-gray-200 h-value-40 p-2.5 text-fs-14 outline-0 mb-5"
                        placeholder="Nom…"
                        {...register("main_traveler", {
                          required: true,
                        })}
                        onChange={() => {
                          clearErrors();
                        }}
                      />
                      {Object.keys(errors).length > 0 &&
                        "main_traveler" in errors && (
                          <div className="flex text-fs-14 px-5 py-3.5 bg-gold rounded-md items-center mb-value-30 bigdesktop:w-1132 bigdesktop:-ml-235">
                            <img
                              className="self-start mr-5"
                              src={warning}
                              alt=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name={`main_traveler`}
                              render={() => (
                                <span className="text-white block font-semibold">
                                  Ce champs est requis.
                                </span>
                              )}
                            />
                          </div>
                        )}
                    </div>
                    <div className="w-320 flex flex-col mr-5">
                      <span className="font-bold mb-2.5 text-fs-14">
                        Nom du vendeur
                      </span>
                      <input
                        type="text"
                        className="rounded-md border border-gray-200 h-value-40 p-2.5 text-fs-14 outline-0 mb-5"
                        placeholder="Nom…"
                        {...register("vendor", {
                          required: true,
                        })}
                        onChange={() => {
                          clearErrors();
                        }}
                      />

                      {Object.keys(errors).length > 0 && "vendor" in errors && (
                        <div className="flex text-fs-14 px-5 py-3.5 bg-gold rounded-md items-center mb-value-30 bigdesktop:w-1132 bigdesktop:-ml-235">
                          <img
                            className="self-start mr-5"
                            src={warning}
                            alt=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name={`vendor`}
                            render={() => (
                              <span className="text-white block font-semibold">
                                Ce champs est requis.
                              </span>
                            )}
                          />
                        </div>
                      )}
                    </div>
                    <div className="w-320 flex flex-col">
                      <span className="font-bold mb-2.5 text-fs-14">
                        Code Selectour + ou Perf +
                      </span>
                      <input
                        type="text"
                        className="rounded-md border border-gray-200 h-value-40 p-2.5 text-fs-14 outline-0 mb-5"
                        placeholder="Code…"
                        {...register("vendor_code")}
                      />
                    </div>
                  </>
                </div>
              </>
            )}
            {api_key.length === 0 && (
              <>
                <div className="text-center">
                  <span className="text-fs-14 leading-lh-25 mb-value-40 block font-bold">
                    Merci de renseigner votre nom, prénom et adresse e-mail pour
                    recevoir votre devis personnalisé sans engagement.
                  </span>
                </div>
                <div className="flex justify-center mb-value-40">
                  <div className="w-320 flex flex-col mr-5">
                    <span className="font-bold mb-2.5 text-fs-14">Nom</span>
                    <input
                      type="text"
                      className="rounded-md border border-gray-200 h-value-40 p-2.5 text-fs-14 outline-0 mb-5"
                      placeholder="Nom…"
                      {...register("surname_traveler", {
                        required: true,
                      })}
                      onChange={() => {
                        clearErrors();
                      }}
                    />
                    {Object.keys(errors).length > 0 &&
                      "surname_traveler" in errors && (
                        <div className="flex text-fs-14 px-5 py-3.5 bg-gold rounded-md items-center mb-value-30 bigdesktop:w-1132 bigdesktop:-ml-235">
                          <img
                            className="self-start mr-5"
                            src={warning}
                            alt=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name={`surname_traveler`}
                            render={() => (
                              <span className="text-white block font-semibold">
                                Ce champs est requis.
                              </span>
                            )}
                          />
                        </div>
                      )}
                  </div>
                  <div className="w-320 flex flex-col mr-5">
                    <span className="font-bold mb-2.5 text-fs-14">Prénom</span>
                    <input
                      type="text"
                      className="rounded-md border border-gray-200 h-value-40 p-2.5 text-fs-14 outline-0 mb-5"
                      placeholder="Prénom…"
                      {...register("name_traveler", {
                        required: true,
                      })}
                      onChange={() => {
                        clearErrors();
                      }}
                    />

                    {Object.keys(errors).length > 0 &&
                      "name_traveler" in errors && (
                        <div className="flex text-fs-14 px-5 py-3.5 bg-gold rounded-md items-center mb-value-30 bigdesktop:w-1132 bigdesktop:-ml-235">
                          <img
                            className="self-start mr-5"
                            src={warning}
                            alt=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name={`name_traveler`}
                            render={() => (
                              <span className="text-white block font-semibold">
                                Ce champs est requis.
                              </span>
                            )}
                          />
                        </div>
                      )}
                  </div>
                  <div className="w-350 flex flex-col">
                    <span className="font-bold mb-2.5 text-fs-14">
                      Adresse e-mail 
                    </span>
                    <input
                      type="text"
                      className="rounded-md border border-gray-200 h-value-40 p-2.5 text-fs-14 outline-0 mb-5"
                      placeholder={`Adresse e-mail`}
                      {...register("email", {
                        required: " Ce champs est requis.",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message:
                            "La valeur saisie ne correspond pas au format de l'email",
                        },
                      })}
                    />
                    {Object.keys(errors).length > 0 && "email" in errors && (
                      <div className="flex text-fs-14 px-5 py-3.5 bg-gold rounded-md items-center mb-value-30 bigdesktop:w-1132 bigdesktop:-ml-235">
                        <img className="self-start mr-5" src={warning} alt="" />
                        <ErrorMessage
                          errors={errors}
                          name={`email`}
                          render={({ message }) => (
                            <span className="text-white block font-semibold">
                              {message}
                            </span>
                          )}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            {Object.keys(errors).length > 0 && "dest" in errors && (
              <div className="flex text-fs-14 px-5 py-3.5 bg-gold rounded-md items-center mb-value-30 bigdesktop:w-1132 bigdesktop:-ml-235">
                <img className="self-start mr-5" src={warning} alt="" />
                <ErrorMessage
                  errors={errors}
                  name={`dest`}
                  render={({ message }) => (
                    <span className="text-white block font-semibold">
                      {message}
                    </span>
                  )}
                />
              </div>
            )}
            {Object.keys(errors).length > 0 && "no_flight" in errors && (
              <div className="flex text-fs-14 px-5 py-3.5 bg-gold rounded-md items-center mb-value-30 bigdesktop:w-1132 bigdesktop:-ml-235">
                <img className="self-start mr-5" src={warning} alt="" />
                <ErrorMessage
                  errors={errors}
                  name={`no_flight`}
                  render={({ message }) => (
                    <span className="text-white block font-semibold">
                      {message}
                    </span>
                  )}
                />
              </div>
            )}
            {Object.keys(errors).length > 0 &&
              Object.keys(destinations).map((key) => {
                if (`transfert_${key}_hidden` in errors) {
                  return (
                    <div
                      key={key}
                      className="flex text-fs-14 px-5 py-3.5 bg-gold rounded-md items-center mb-value-30 bigdesktop:w-1132 bigdesktop:-ml-235"
                    >
                      <img className="self-start mr-5" src={warning} alt="" />
                      <ErrorMessage
                        errors={errors}
                        name={`transfert_${key}_hidden`}
                        render={({ message }) => (
                          <span className="text-white block font-semibold">
                            {message}
                          </span>
                        )}
                      />
                    </div>
                  );
                }
                return null; // Handle cases where there is no error for the key
              })}

            <ValidateButton
              classes={
                "p-16-35 leading-lh-15 block mx-auto main-bg-color text-white br-26 text-fs-14 w-value-226 mt-value-30"
              }
              button={{
                label: "Valider",
                type: "SELECT_NAMES",
              }}
              validateStrings={validateStrings}
            />
          </div>
          <div className="flex flex-col items-center mt-value-30">
            <a className="block w-125" href="/">
              <img className="w-full" src={logo} alt="" />
            </a>
          </div>
        </>
      )}
      {mode === "validate" && Success && (
        <div className="bg-white px-5 py-value-40 rounded-md main-color validation-section">
          <div className="max-w-640 mx-auto">
            <h3 className="gold-color text-fs-21 leading-lh-27 merriweather font-bold text-center">
              Merci d’avoir choisi Austral Lagons
            </h3>
            {case_no !== undefined && (
              <span className="block my-5 main-color text-fs-18 font-bold text-center">
                Votre devis est enregistré sous la référence n° {case_no}
              </span>
            )}
            {/* Détails des prestations */}
            {Array.isArray(items) && items.length > 0 && (
              <div className="relative">
                <h6 className="mb-0">
                  <button
                    className={`relative text-fs-18 main-color flex items-center w-full py-4 font-semibold text-left transition-all ease-in border-b border-solid cursor-pointer border-slate-100 text-slate-700 rounded-t-1 group text-dark-500 custom-select-details ${
                      isOpen ? "arrow-top" : "arrow-down"
                    }`}
                    data-collapse-target="collapse-1"
                    onClick={(e) => toggleAccordion(e)}
                  >
                    <span>Détails des prestations</span>
                  </button>
                </h6>
                <div
                  data-collapse="collapse-1"
                  className={`overflow-hidden transition-all duration-300 ease-in-out ${
                    isOpen ? "" : "h-0"
                  }`}
                >
                  <table
                    className="border-collapse border border-slate-400"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    <tbody>
                      {(items || []).map((item, key) => (
                        <tr key={key}>
                          <td
                            className="border border-slate-300"
                            style={{ padding: "5px" }}
                            key={key}
                          >
                            {item}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {api_key.length > 0 && (
              <div className="flex justify-center">
                {case_prepare_url !== undefined && (
                  <LinkButton
                    button={{
                      label: "Personnaliser votre devis",
                    }}
                    link={case_prepare_url}
                  />
                )}
                {case_book_url !== undefined && (
                  <LinkButton
                    button={{
                      label: "Réserver",
                    }}
                    link={case_book_url}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {mode === "validate" && Loading && (
        <div className="bg-white px-5 py-value-40 rounded-md main-color validation-section">
          <div className="scale-50 h-full w-full flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="200px"
              height="200px"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
            >
              <g transform="rotate(0 50 50)">
                <rect
                  x="47"
                  y="24"
                  rx="3"
                  ry="6"
                  width="6"
                  height="12"
                  fill="#0d2768"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.9166666666666666s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(30 50 50)">
                <rect
                  x="47"
                  y="24"
                  rx="3"
                  ry="6"
                  width="6"
                  height="12"
                  fill="#0d2768"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.8333333333333334s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(60 50 50)">
                <rect
                  x="47"
                  y="24"
                  rx="3"
                  ry="6"
                  width="6"
                  height="12"
                  fill="#0d2768"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.75s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(90 50 50)">
                <rect
                  x="47"
                  y="24"
                  rx="3"
                  ry="6"
                  width="6"
                  height="12"
                  fill="#0d2768"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.6666666666666666s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(120 50 50)">
                <rect
                  x="47"
                  y="24"
                  rx="3"
                  ry="6"
                  width="6"
                  height="12"
                  fill="#0d2768"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.5833333333333334s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(150 50 50)">
                <rect
                  x="47"
                  y="24"
                  rx="3"
                  ry="6"
                  width="6"
                  height="12"
                  fill="#0d2768"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.5s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(180 50 50)">
                <rect
                  x="47"
                  y="24"
                  rx="3"
                  ry="6"
                  width="6"
                  height="12"
                  fill="#0d2768"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.4166666666666667s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(210 50 50)">
                <rect
                  x="47"
                  y="24"
                  rx="3"
                  ry="6"
                  width="6"
                  height="12"
                  fill="#0d2768"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.3333333333333333s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(240 50 50)">
                <rect
                  x="47"
                  y="24"
                  rx="3"
                  ry="6"
                  width="6"
                  height="12"
                  fill="#0d2768"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.25s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(270 50 50)">
                <rect
                  x="47"
                  y="24"
                  rx="3"
                  ry="6"
                  width="6"
                  height="12"
                  fill="#0d2768"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.16666666666666666s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(300 50 50)">
                <rect
                  x="47"
                  y="24"
                  rx="3"
                  ry="6"
                  width="6"
                  height="12"
                  fill="#0d2768"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.08333333333333333s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(330 50 50)">
                <rect
                  x="47"
                  y="24"
                  rx="3"
                  ry="6"
                  width="6"
                  height="12"
                  fill="#0d2768"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="0s"
                    repeatCount="indefinite"
                  ></animate>
                </rect>
              </g>
            </svg>
          </div>
        </div>
      )}
      {mode === "validate" && Error && (
        <div className="flex flex-col ">
          <div className=" mb-value-80 flex text-fs-14 px-5 py-3.5 bg-gold rounded-md items-center mb-value-30 bigdesktop:w-1132 bigdesktop:-ml-235">
            <img className="self-start mr-5" src={warning} alt="" />
            <span className="text-white block font-semibold">
              Une erreur est survenue lors de la recherche des données du devis
              temps réel.
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default ValidationSection;

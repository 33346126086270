import { createSlice } from "@reduxjs/toolkit";
import { current } from "@reduxjs/toolkit";
import { parseHotelDatas } from "../selectors/form";
import {
  getProductsStayDates,
  calculateAvailableDays,
} from "../selectors/userSelection";
import { getLastKey, getFirsttKey } from "../helper";
import { format, addDays, differenceInDays, sub } from "date-fns";

const userSelectionSlice = createSlice({
  name: "userSelection",
  initialState: {
    components: [
      {
        component: "date",
        mode: "popup",
      },
    ],
    form_data: {
      num_island: 0,
      islands: {},
      available_days: 0,
    },
    data: {
      staydates: {},
      hotels: {},
      flight: {},
      excursions: {},
      dates: {},
      transfert: {},
      rental: {},
      assurance: "",
      outboundDate: "",
      inboundDate: "",
      vendor: "",
      main_traveler: "",
      name_traveler: "",
      surname_traveler: "",
      email: "",
      vendor_code: "",
      is_multi_island: "0",
      mode: "edit",
      api_key: "",
      no_flight: "",
      special_request: "",
    },
  },
  reducers: {
    addComponent: (state, action) => {
      var {
        type,
        disabled,
        mode,
        flight_type,
        dest,
        default_values,
        data_transfert,
        islands_code,
        island,
        next_dest,
        index,
        flag,
      } = action.payload;
      let component = {};
      switch (type) {
        case "transfert":
          component["component"] = type;
          component["mode"] = mode;
          component["dest"] = dest;
          component["island"] = island;
          component["data"] = data_transfert;
          if (component["mode"] === "button") {
            component["disabled"] = disabled;
          }
          component["data"] = data_transfert;
          const new_order = [
            ...current(state.components).slice(0, index),
            component,
            ...current(state.components).slice(index),
          ];
          state.components = [];
          state.components = [...new_order];
          break;

        case "islands":
          component["component"] = type;
          component["mode"] = mode;
          component["dest"] = dest;
          if (component["mode"] === "popup") {
            component["islands_code"] = islands_code;
          }
          if (component["mode"] === "button") {
            component["disabled"] = disabled;
          }
          component["num_island"] = state.form_data["num_island"];
          if (index !== undefined) {
            let new_order = [
              ...current(state.components).slice(0, index),
              component,
              ...current(state.components).slice(index),
            ];
            state.components = [];
            state.components = [...new_order];
          } else {
            state.components.push(component);
          }
          state.form_data["num_island"] = state.form_data["num_island"] + 1;

          break;

        case "flight":
          component["component"] = type;
          component["mode"] = mode;
          if (component["mode"] === "button") {
            component["disabled"] = disabled;
          }
          component["flight_type"] = flight_type;
          state.components.push(component);
          break;

        case "hotels":
          let num = 0;
          const components = state.components;
          const create_hotel =
            index === undefined ||
            (index in components && components[index]["component"] !== "hotels")
              ? true
              : false;

          // First Hotels Wrapper
          if (create_hotel === true) {
            const index_parent =
              index === undefined ? state.components.length : index;
            component["component"] = type;
            component["mode"] = mode;
            component["dest"] = dest;
            component["island"] = island;
            component["index_parent"] = index_parent;
            component["children"] = [];
            component["children"].push({
              component: "hotel",
              disabled,
              mode,
              dest,
              num,
              index_parent,
              default_values,
              island,
              flag,
            });
            if (index === undefined) {
              state.components.push(component);
            } else {
              let new_order = [
                ...current(state.components).slice(0, index),
                component,
                ...current(state.components).slice(index),
              ];
              state.components = [];
              state.components = [...new_order];
            }
          } else {
            num = Object.keys(state.components[index]["children"]).length;
            state.components[index]["mode"] = mode;
            state.components[index]["children"].push({
              component: "hotel",
              disabled,
              mode,
              dest,
              num,
              index_parent: index,
              default_values,
              island,
              flag,
            });
          }

          break;

        case "excursion":
          component["component"] = type;
          component["mode"] = mode;
          component["dest"] = dest;
          component["next_dest"] = next_dest;
          if (component["mode"] === "button") {
            component["disabled"] = disabled;
          }
          state.components.push(component);
          break;

        default:
          component["component"] = type;
          component["mode"] = mode;
          if (component["mode"] === "button") {
            component["disabled"] = disabled;
          }
          state.components.push(component);

          break;
      }
    },
    removeComponent: (state, action) => {
      let { type, index, num } = action.payload;
      switch (type) {
        case "hotel":
          state.components[index]["children"].splice(num, 1);
          break;

        default:
          if (state.components[index]["component"] === type)
            state.components.splice(index, 1);
          break;
      }
    },
    recapMode: (state) => {
      for (let i = 0; i < state.components.length; i++) {
        state.components[i]["mode"] = "recap";
      }
    },
    ComponentMode: (state, action) => {
      let { type, mode, num, disabled, index, datas, flag, default_values } =
        action.payload;
      switch (type) {
        case "hotel":
          state.components[index]["mode"] = mode;
          state.components[index]["children"][num]["mode"] = mode;
          if (flag !== undefined)
            state.components[index]["children"][num]["flag"] = flag;
          if (default_values !== undefined)
            state.components[index]["children"][num]["default_values"] =
              default_values;
          if (
            mode !== "button" &&
            "disabled" in state.components[index]["children"][num]
          )
            delete state.components[index]["children"][num]["disabled"];
          if (mode === "button")
            state.components[index]["children"][num]["disabled"] = disabled;
          break;

        case "hotels":
          if (index !== undefined) {
            state.components[index]["mode"] = mode;
            if (mode !== "button" && "disabled" in state.components[index])
              delete state.components[index]["disabled"];
            if (mode === "button")
              state.components[index]["disabled"] = disabled;
            state.components[index]["children"].forEach((_, key) => {
              state.components[index]["children"][key]["mode"] = mode;
              state.components[index]["children"][key]["disabled"] = disabled;
            });
          } else {
            for (let i = 0; i < state.components.length; i++) {
              if (state.components[i]["component"] === type) {
                state.components[i]["mode"] = mode;
                if (mode === "button")
                  state.components[i]["disabled"] = disabled;
              }
            }
          }
          break;

        case "excursion":
          if (index !== undefined) {
            state.components[index]["mode"] = mode;
            if (mode !== "button" && "disabled" in state.components[index])
              delete state.components[index]["disabled"];
            if (mode === "button") {
              state.components[index]["disabled"] = disabled;
              if (datas !== undefined) state.components[index]["datas"] = datas;
            }
          } else {
            for (let i = 0; i < state.components.length; i++) {
              if (state.components[i]["component"] === type) {
                state.components[i]["mode"] = mode;
                if (mode === "button") {
                  state.components[i]["disabled"] = disabled;
                  if (datas !== undefined) state.components[i]["datas"] = datas;
                }
              }
            }
          }

          break;

        default:
          if (index !== undefined) {
            state.components[index]["mode"] = mode;
            if (mode !== "button" && "disabled" in state.components[index])
              delete state.components[index]["disabled"];
            if (mode === "button")
              state.components[index]["disabled"] = disabled;
          } else {
            for (let i = 0; i < state.components.length; i++) {
              if (state.components[i]["component"] === type) {
                state.components[i]["mode"] = mode;
                if (mode === "button")
                  state.components[i]["disabled"] = disabled;
              }
            }
          }
          break;
      }
    },
    updateDatas: (state, action) => {
      let {
        button: { type },
        block,
        is_multi_island,
        vendor,
        name_traveler,
        surname_traveler,
        main_traveler,
        vendor_code,
        extra_data,
        mode,
        email,
        index_parent,
        no_flight,
        special_request,
        ...datas
      } = action.payload;

      var available_days = 0;
      switch (type) {
        case "SELECT_STAYDATES":
          const { startDate, endDate } = datas.staydates;
          const start_date_formatted = format(
            new Date(startDate),
            "yyyy-MM-dd"
          );
          const end_date_formatted = format(new Date(endDate), "yyyy-MM-dd");
          // Extract year, month, and day from the string
          const [start_year, start_month, start_day] = startDate
            .split("-")
            .map(Number);
          const date1 = new Date(start_year, start_month - 1, start_day, 0, 0);
          const [end_year, end_month, end_day] = endDate.split("-").map(Number);
          const date2 = new Date(end_year, end_month - 1, end_day, 1, 0);
          available_days = differenceInDays(new Date(date2), new Date(date1));
          state["data"]["current_dest"] = datas["dest"];
          state["data"]["dest"] = datas["dest"];
          state["data"]["is_multi_island"] = is_multi_island;
          state["data"]["outboundDate"] = start_date_formatted;
          state["data"]["inboundDate"] = end_date_formatted;
          state["data"]["staydates"]["startDate"] = start_date_formatted;
          state["data"]["staydates"]["endDate"] = end_date_formatted;
          state["data"]["api_key"] = datas.api_key;
          state["form_data"]["available_days"] = available_days;
          break;

        case "SELECT_TRAVELERS":
          state["data"]["adults"] = datas["adults"];
          state["data"]["children"] = datas["children"];
          state["data"]["next_dest"] = datas["next_dest"];
          state["data"]["profile"] = datas["profile"];
          state["data"]["weddingdate"] = datas["weddingdate"];
          [...Array(parseInt(state["data"]["children"]))].forEach((_, i) => {
            state["data"][`age_${i + 1}`] = datas[`age_${i + 1}`];
          });
          break;

        case "SELECT_FLIGHT":
          const { cabin_aller, cabin_retour, departureCity, code } =
            datas[`flight`]["external"];

          state["data"]["flight"]["external"] = {
            cabin_aller,
            cabin_retour,
            departure_city: departureCity,
            code,
            total_lag: datas["total_lag"],
            lag_aller: datas["lag_aller"],
            lag_retour: datas["lag_retour"],
            details: datas["duration"],
          };
          const outboundDate = format(
            sub(new Date(datas.staydates.startDate), {
              days: datas["lag_aller"],
            }),
            "yyyy-MM-dd"
          );

          const inboundDate = format(
            addDays(new Date(datas.staydates.endDate), datas["lag_retour"]),
            "yyyy-MM-dd"
          );

          state["data"]["outboundDate"] = outboundDate;
          state["data"]["inboundDate"] = inboundDate;
          break;

        case "SELECT_HOTELS":
          const { num, dest, island, index_parent } = block;
          const hotel = parseHotelDatas(datas, block, extra_data);
          const { start_date, end_date } = getProductsStayDates(
            current(state).data,
            hotel["nbr_nights"],
            num,
            index_parent
          );

          if (!(index_parent in state["data"]["hotels"]))
            state["data"]["hotels"][index_parent] = {};
          if (!(num in state["data"]["hotels"][index_parent])) {
            state["data"]["hotels"][index_parent][num] = {
              ...hotel,
              dest,
              island,
              start_date,
              end_date,
            };
          } else {
            state["data"]["hotels"][index_parent][num] = {};
            state["data"]["hotels"][index_parent][num] = {
              ...hotel,
              dest,
              start_date,
              island,
              end_date,
            };
          }

          available_days = calculateAvailableDays(current(state).data);
          state["form_data"]["available_days"] = available_days;
          if (available_days === 0) {
            if (island === undefined) {
              //cas mono desti
              if (Object.keys(state["data"]["dates"]).length === 0) {
                state["data"]["dates"][datas["dest"]] = [
                  {
                    start_day: state["data"]["staydates"]["startDate"],
                    end_day: state["data"]["staydates"]["endDate"],
                  },
                ];
              } else if (datas["next_dest"] !== "none") {
                // cas multi desti
                state["data"]["dates"][datas["next_dest"]] = [
                  {
                    start_day:
                      state["data"]["dates"][datas["dest"]][0]["end_day"],
                    end_day: end_date,
                  },
                ];
              }
            } else {
              const first_num = getFirsttKey(
                state["data"]["hotels"][index_parent]
              );
              if (!(island in state["data"]["dates"])) {
                state["data"]["dates"][island] = [
                  {
                    start_day:
                      state["data"]["hotels"][index_parent][first_num][
                        "start_date"
                      ],
                    end_day: end_date,
                  },
                ];
              } else {
                state["data"]["dates"][island].push({
                  start_day:
                    state["data"]["hotels"][index_parent][first_num][
                      "start_date"
                    ],
                  end_day: end_date,
                });
              }
            }
          }

          break;

        case "SELECT_TRANSFERT":
          const type_transfert = datas[`type_transfert`];
          const desti = block.dest;
          if (type_transfert === "transfert") {
            if (desti in state["data"]["rental"]) {
              delete state["data"]["rental"][desti];
            }
            if (
              desti in state["data"]["transfert"] &&
              Object.keys(state["data"]["transfert"][desti]).length > 0
            ) {
              state["data"]["transfert"][desti]["code"] =
                datas[`transfert`][desti]["code"];
            }
            if (!(desti in state["data"]["transfert"])) {
              state["data"]["transfert"][desti] = {
                code: datas[`transfert`][desti]["code"],
              };
            }
            state["data"]["transfert"][desti]["bl_linked_transfers"] =
              datas[`bl_linked_transfers`];
          }

          if (type_transfert === "rental") {
            if (desti in state["data"]["transfert"]) {
              delete state["data"]["transfert"][desti];
            }
            if (!(desti in state["data"]["rental"])) {
              state["data"]["rental"][desti] = {};
            }
            state["data"]["rental"][desti]["subprovision_code"] =
              datas[`transfert`][desti]["code"];
            state["data"]["rental"][desti]["date_start"] =
              state["data"]["dates"][desti][0]["start_day"];
            state["data"]["rental"][desti]["date_end"] =
              state["data"]["dates"][desti][0]["end_day"];
            state["data"]["rental"][desti]["rental_code"] =
              datas[`rental_code`];
          }

          break;

        case "SELECT_ISLAND":
          const num_island = state.form_data["num_island"] - 1;
          const island_code = datas[`island_${num_island}`];
          state.form_data.islands[num_island] = island_code;
          break;

        case "CALL_TO_ACTION_NEXT_DEST_HOTELS":
          state["data"] = {
            ...state["data"],
            current_dest: datas["current_dest"],
          };
          const dest_start_date = datas.staydates.startDate;
          const dest_end_date = datas.staydates.endDate;
          const [dest_start_year, dest_start_month, dest_start_day] =
            dest_start_date.split("-").map(Number);
          const date_1 = new Date(
            dest_start_year,
            dest_start_month - 1,
            dest_start_day,
            0,
            0
          );
          const [dest_end_year, dest_end_month, dest_end_day] = dest_end_date
            .split("-")
            .map(Number);
          const date_2 = new Date(
            dest_end_year,
            dest_end_month - 1,
            dest_end_day,
            1,
            0
          );

          const stayed_days =
            differenceInDays(new Date(date_2), new Date(date_1)) -
            state["form_data"]["available_days"];
          const dest_end_date_no_format = addDays(
            new Date(dest_start_date),
            stayed_days
          );
          const dest_end_date_formatted = format(
            dest_end_date_no_format,
            "yyyy-MM-dd"
          );
          state["data"]["dates"][datas["dest"]] = [
            {
              start_day: dest_start_date,
              end_day: dest_end_date_formatted,
            },
          ];
          break;

        case "CALL_TO_ACTION_NEXT_ISLAND":
          const last_key = getLastKey(state["data"]["hotels"]);
          const last_num = getLastKey(state["data"]["hotels"][last_key]);
          const first_num = getFirsttKey(state["data"]["hotels"][last_key]);
          const code_island = state["data"]["hotels"][last_key][0]["island"];
          if (!(code_island in state["data"]["dates"])) {
            state["data"]["dates"][code_island] = [
              {
                start_day:
                  state["data"]["hotels"][last_key][first_num]["start_date"],
                end_day:
                  state["data"]["hotels"][last_key][last_num]["end_date"],
              },
            ];
          } else {
            state["data"]["dates"][code_island].push({
              start_day:
                state["data"]["hotels"][last_key][first_num]["start_date"],
              end_day: state["data"]["hotels"][last_key][last_num]["end_date"],
            });
          }

          break;

        case "SELECT_EXCURSION":
          const excursion = datas.excursion;
          const excursion_datas = block.datas;
          if (Object.keys(state["data"]["excursions"]).length > 0) {
            state["data"]["excursions"] = {};
          }

          Object.keys(excursion).forEach((desti) => {
            if (
              Array.isArray(excursion[desti]["code"]) &&
              excursion[desti]["code"].length > 0
            ) {
              for (let code of excursion[desti]["code"]) {
                if (!(desti in state["data"]["excursions"]))
                  state["data"]["excursions"][desti] = {};
                if (!(code in state["data"]["excursions"][desti]))
                  state["data"]["excursions"][desti][code] = {};
                if (code in excursion[desti]) {
                  let validity_day = excursion[desti][code]["validity_day"];
                  state["data"]["excursions"][desti][code] = {
                    validity_day: validity_day,
                    prestation_code:
                      excursion_datas[desti]["data"][code]["prestation_code"],
                    prestation_sub_code:
                      excursion_datas[desti]["data"][code][
                        "prestation_sub_code"
                      ],
                    supplier_code:
                      excursion_datas[desti]["data"][code]["supplier_code"],
                    service_code:
                      excursion_datas[desti]["data"][code]["service_code"],
                  };
                }
              }
            }
            if (
              typeof excursion[desti]["code"] === "string" &&
              excursion[desti]["code"].length > 0
            ) {
              let code = excursion[desti]["code"];
              if (!(desti in state["data"]["excursions"]))
                state["data"]["excursions"][desti] = {};
              if (!(code in state["data"]["excursions"][desti]))
                state["data"]["excursions"][desti][code] = {};
              if (code in excursion[desti]) {
                let validity_day = excursion[desti][code]["validity_day"];
                state["data"]["excursions"][desti][code] = {
                  validity_day: validity_day,
                  prestation_code:
                    excursion_datas[desti]["data"][code]["prestation_code"],
                  prestation_sub_code:
                    excursion_datas[desti]["data"][code]["prestation_sub_code"],
                  supplier_code:
                    excursion_datas[desti]["data"][code]["supplier_code"],
                  service_code:
                    excursion_datas[desti]["data"][code]["service_code"],
                };
              }
            }
          });
          break;

        case "SELECT_ASSURANCE":
          if ("index" in datas) delete datas["index"];
          state["data"] = { ...state["data"], assurance: datas["assurance"] };
          break;

        case "SELECT_NAMES":
          if (vendor !== undefined) state["data"]["vendor"] = vendor;
          if (main_traveler !== undefined)
            state["data"]["main_traveler"] = main_traveler;
          if (name_traveler !== undefined)
            state["data"]["name_traveler"] = name_traveler;
          if (surname_traveler !== undefined)
            state["data"]["surname_traveler"] = surname_traveler;
          if (vendor_code !== undefined)
            state["data"]["vendor_code"] = vendor_code;
          if (email !== undefined) state["data"]["email"] = email;
          if (special_request !== undefined)
            state["data"]["special_request"] = special_request;
          state["data"]["mode"] = "validate";

          break;

        case "UPDATE_MODE":
          state["data"]["mode"] = mode;
          break;

        case "UPDATE_FLIGHT_SELECTION":
          state["data"]["no_flight"] = no_flight;
          break;

        case "UPDATE_CURRENT_DEST":
          state["data"]["current_dest"] = datas["dest"];
          break;

        default:
          state["data"] = { ...state["data"], ...datas };
          break;
      }
    },
    removeDatas: (state, action) => {
      let {
        block: { component, dest, island, num_island },
        index,
        num,
        flag,
      } = action.payload;
      switch (component) {
        case "hotel":
          if (flag === "WIPE_DATES") {
            if (island !== undefined && island in state["data"]["dates"]) {
              const length = state["data"]["dates"][island].length;
              state["data"]["dates"][island].splice(length - 1, 1);
              if (state["data"]["dates"][island].length === 0)
                delete state["data"]["dates"][island];
            }
          } else {
            const nbr_nights =
              state["data"]["hotels"][index][num]["nbr_nights"];
            state["form_data"]["available_days"] += nbr_nights;
            delete state["data"]["hotels"][index][num];
            if (dest in state["data"]["dates"] && island === undefined)
              delete state["data"]["dates"][dest];
          }

          break;

        case "transfert":
          if (dest in state["data"]["transfert"]) {
            delete state["data"]["transfert"][dest];
          }
          if (dest in state["data"]["rental"]) {
            delete state["data"]["rental"][dest];
          }
          break;

        case "hotels":
          if (index in state["data"]["hotels"]) {
            let nbr_nights = 0;
            Object.keys(state["data"]["hotels"][index]).forEach((key) => {
              nbr_nights += state["data"]["hotels"][index][key]["nbr_nights"];
            });
            state["form_data"]["available_days"] += nbr_nights;
            delete state["data"]["hotels"][index];
            if (dest in state["data"]["dates"] && island === undefined)
              delete state["data"]["dates"][dest];

            if (island !== undefined && island in state["data"]["dates"]) {
              const length = state["data"]["dates"][island].length;
              state["data"]["dates"][island].splice(length - 1, 1);
              if (state["data"]["dates"][island].length === 0)
                delete state["data"]["dates"][island];
            }
          }
          break;

        case "flight":
          if ("external" in state["data"]["flight"]) {
            delete state["data"]["flight"]["external"];
          }
          break;

        case "excursion":
          state["data"]["excursions"] = {};
          break;

        case "islands":
          state.form_data["num_island"] = state.form_data["num_island"] - 1;
          delete state["form_data"]["islands"][num_island];
          break;

        case "assurance":
          state["data"]["assurance"] = "";
          break;
        default:
        // do nothing
      }
    },
    updateCurrentDate: (state, action) => {
      const { dest } = action.payload;
      state["data"] = {
        ...state["data"],
        current_dest: dest,
      };
    },
  },
});

export const userSelectionReducer = userSelectionSlice.reducer;

export const {
  addComponent,
  removeComponent,
  recapMode,
  removeDatas,
  ComponentMode,
  updateDatas,
  updateCurrentDate,
} = userSelectionSlice.actions;
